<!-- Schema defined in utils/schema/cover-letter-schema.js -->
<script setup>
  const props = defineProps({
    errors: { type: Object, required: true},
    values: { type: Object, required: true},
    required: { type: Boolean, required: true },
    job: { type: Object, required: true },
  })
  const { user } = useUser()

  const generatedLetter = ref(null)
  const letterQuestions = ref(false)

  const canGenerateLetter = () => {
    return generatedLetter.value.canGenerateLetter()
  }
  const getGeneratedLetterPdf = () => {
    return generatedLetter.value.getGeneratedLetterPdf()
  }
  defineExpose({ getGeneratedLetterPdf, canGenerateLetter })
</script>

<template>
  <div>
    <FormField
      class="basis-full"
      id="letter"
      accept="application/pdf"
      :error="props.errors.letter"
      label="Lettre de motivation (format PDF)"
      name="letter"
      :required="props.required"
      type="file"
    />
    <p v-if="job.canLetterQuestions" class="cursor-pointer pt-1" @click="letterQuestions = letterQuestions ? false : true">
      Vous n’en avez pas ? Répondez à
      <span class="text-viking-400 hover:text-viking-500">3 questions de l'institution !</span>
    </p>
    <Transition>
      <div class="pt-3" v-if="letterQuestions">
        <p class="">Pourquoi souhaitez-vous nous rejoindre ?</p>
        <FormField
          class="basis-full pt-2"
          id="motivationLetterQuestion1"
          :error="props.errors.motivationLetterQuestion1"
          name="motivationLetterQuestion1"
          required
          rows="3"
          type="textarea"
        />
        <p class="pt-2">Pourquoi êtes-vous fait pour ce poste ?</p>
        <FormField
          class="basis-full pt-2"
          id="motivationLetterQuestion2"
          :error="props.errors.motivationLetterQuestion2"
          name="motivationLetterQuestion2"
          required
          rows="3"
          type="textarea"
        />
        <p class="pt-2">Quelque chose à ajouter ?</p>
        <FormField
          class="basis-full pt-2"
          id="motivationLetterQuestion3"
          :error="props.errors.motivationLetterQuestion3"
          name="motivationLetterQuestion3"
          required
          rows="3"
          type="textarea"
        />
      </div>
    </Transition>
    <FormGeneratedLetter
      ref="generatedLetter"
      :candidateFirstName="user?.firstName || values.firstName"
      :candidateLastName="user?.lastName || values.lastName"
      :presenter="job.speaker"
      :employerName="props.job.employer.name"
      :answer1="values.motivationLetterQuestion1"
      :answer2="values.motivationLetterQuestion2"
      :answer3="values.motivationLetterQuestion3"
      question1="Pourquoi souhaitez-vous nous rejoindre ?"
      question2="Pourquoi êtes-vous fait pour ce poste ?"
      question3="Quelque chose à ajouter ?"
    />
  </div>
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s, max-height 0.5s;
  max-height: 800px;
}

.v-enter-active {
  transition: opacity 0.5s ease-in-out, max-height 0.5s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>