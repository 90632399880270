import * as yup from 'yup'

function _requiredTest(field) {
  return field.test('letterOrQuestions', 'Envoyez-nous votre lettre de motivation ou répondez aux 3 questions', function (item) {
    return (
      item ||
      this.parent.letter ||
      (this.parent.motivationLetterQuestion1 && this.parent.motivationLetterQuestion2 && this.parent.motivationLetterQuestion3)
    )
  })
}

export default function coverLetterSchema(required = true, canAnswerQuestions = true) {
  let letterField = yupFile({ types: ['application/pdf'] }).label('Lettre de motivation')
  let schema

  if (canAnswerQuestions) {
    schema = {
      letter: letterField,
      motivationLetterQuestion1: yup.string().label('Question 1'),
      motivationLetterQuestion2: yup.string().label('Question 2'),
      motivationLetterQuestion3: yup.string().label('Question 3')
    }

    if (required) {
      Object.keys(schema).forEach(key => {
        schema[key] = _requiredTest(schema[key])
      })
    }
  } else {
    if (required) letterField = letterField.required('Lettre de motivation requise')
    schema = { letter: letterField }
  }

  return yup.object(schema)
}
