<script setup>
  const { $html2pdf } = useNuxtApp()
  const props = defineProps({
    candidateFirstName: { required: true, type: String },
    candidateLastName: { required: true, type: String },
    presenter: { type: Object },
    employerName: { required: true, type: String },
    question1: { required: true, type: String },
    question2: { required: true, type: String },
    question3: { required: true, type: String },
    answer1: { required: true, type: String },
    answer2: { required: true, type: String },
    answer3: { required: true, type: String },
  })

  const canGenerateLetter = () => {
    return props.answer1 || props.answer2 || props.answer3
  }

  const getGeneratedLetterPdf = async () => {
    const element = document.getElementById('generatedLetter')
    // clone the element: https://stackoverflow.com/questions/60557116/html2pdf-wont-print-hidden-div-after-unhiding-it/60558415#60558415
    const clonedElement = element.cloneNode(true)
    clonedElement.classList.remove('hidden')
    clonedElement.classList.add('block')

    // https://www.npmjs.com/package/html2pdf.js/v/0.9.0#options
    const blob = await $html2pdf().from(clonedElement).outputPdf('blob', 'my-invoice.pdf')
    clonedElement.remove()
    const pdf = new File(
      [blob],
      `${props.candidateFirstName}-${props.candidateLastName}-motivation.pdf`,
      { type: 'application/pdf' }
    )

    return pdf
  }
  defineExpose({ getGeneratedLetterPdf, canGenerateLetter })
</script>

<template>
  <div id="generatedLetter" hidden class="m-20 font-special">
    <div id="header" class="grid grid-cols-2 grid-rows-2  text-2xl font-bold">
      <div class="col-start-1 row-start-1">
        <p>{{ props.candidateFirstName }} {{ props.candidateLastName }}</p>
      </div>
      <div class="col-start-2 row-start-2 ml-auto">
        <p v-if="props.presenter">{{ props.presenter.firstName }} {{ props.presenter.lastName }}</p>
        <p>{{ props.employerName }}</p>
      </div>
    </div>
    <div id="content" class="pt-20 font-bold space-y-10">
      <p class="text-sm font-light">Lettre de motivation générée par Profil Public</p>
      <div class="space-y-5">
        <p class="text-xl">{{ props.question1 }}</p>
        <p class="font-medium">{{ props.answer1 }}</p>
      </div>
      <div class="space-y-5">
        <p class="text-xl">{{ props.question2 }}</p>
        <p class="font-medium">{{ props.answer2 }}</p>
      </div>
      <div class="space-y-5">
        <p class="text-xl">{{ props.question3 }}</p>
        <p class="font-medium">{{ props.answer3 }}</p>
      </div>
      <div id="footer" class="">
        <p class="text-right font-bold text-2xl">{{ props.candidateFirstName }} {{ props.candidateLastName }}</p>
      </div>
    </div>
  </div>
</template>